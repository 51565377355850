<template>
  <div class="row mb-0 mb-xl-1">
    <div class="col-auto d-none d-sm-block">
      <h3><strong>Snapshots</strong></h3>
      <span class="text-muted">Create or manage snapshots for DocQ.</span>
    </div>
    <div class="col-auto ms-auto text-end mt-n1">
      <div class="row">
        <div class="col-md-6 col-xl-auto col-md-auto p-1">
          <el-input
            v-model="filters.query"
            clearable
            placeholder="Search"
            prefix-icon="fas fa-search"
            class="w-100 card"
            style="max-width: 2100px;"
          />
        </div>
        <div class="col-md-6 col-xl-auto col-md-auto p-1">
          <el-select
            v-model="filters.types"
            clearable
            placeholder="Status"
            multiple
            collapse-tags
            class="w-100 card"
          >
            <el-option
              v-for="type in types"
              :key="type.key"
              :label="type.label"
              :value="type.key"
            />
          </el-select>
        </div>
        <div class="col-md-6 col-xl-auto p-1" v-if="showBtn">
          <router-link to="/snapshots/create">
            <el-button type="primary" plain class="w-100">New Snapshot</el-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <SnapshotsTable :loading="loading" :snapshots="filteredSnapshots" />
</template>

<script>
  import SnapshotsTable from '@/views/Snapshots/SnapshotsTable'
  export default {
    name: 'Snapshots',
    components: { SnapshotsTable },
    data() {
      return {
        showBtn: false,
        snapshotsList: [],
        selectedSnapshot: undefined,
        loading: false,
        filters: {
          query: '',
          types: [],
        },
        types: [
          {
            key: 'DRAFT',
            label: 'Draft',
          },
          {
            key: 'RELEASED',
            label: 'Released',
          },
        ],
      }
    },
    computed: {
      filteredSnapshots() {
        const { query = '', types = [] } = this.filters
        const sanitizedQuery = query.trim().toLowerCase()
        return this.snapshotsList.filter(({ version, status }) => {
          return (
            [version, status].some(val => val.toLowerCase().includes(sanitizedQuery)) &&
            (!types.length || types.includes(status))
          )
        })
      },
    },
    methods: {
      async loadData() {
        try {
          this.loading = true
          this.snapshotsList = await this.$services.snapshots.get()
        } finally {
          this.loading = false
        }
      },
      async validatePermission() {
        try {
          this.showBtn = await this.$services.users.validate('MANAGE_SNAPSHOTS')
        } catch (error) {
          console.log(error)
        }
      },
    },
    mounted() {
      this.loadData()
      this.validatePermission()
    },
  }
</script>

<style scoped></style>
