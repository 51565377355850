<template>
  <div class="row mb-0 mb-xl-1">
    <div class="col-auto d-none d-sm-block">
      <h3 class="h3 mb-3">Snapshot Details</h3>
    </div>
    <div class="col-auto ms-auto text-end mt-n1" v-if="item != null">
      <div class="row" v-if="item.status === 'DRAFT'">
        <div
          class="col-md-6 col-xl-auto col-md-auto p-1"
          v-if="editMode === false && managePermission"
        >
          <el-button
            icon="el-icon-edit"
            class="w-70"
            type="primary"
            size="small"
            style="min-width:120px;font-size:14px"
            @click="handleEdit"
          >
            Edit
          </el-button>
        </div>
        <div
          class="col-md-6 col-xl-auto col-md-auto p-1"
          v-if="editMode === true && managePermission"
        >
          <el-button
            icon="el-icon-upload"
            class="w-70"
            type="warning"
            size="small"
            style="min-width:120px;font-size:14px"
            @click="handleSave"
          >
            Save
          </el-button>
        </div>
        <div class="col-md-6 col-xl-auto col-md-auto p-1" v-if="managePermission">
          <el-button
            icon="el-icon-delete"
            class="w-70"
            type="danger"
            size="small"
            style="min-width:120px;font-size:14px"
            @click="handleDelete"
          >
            Delete
          </el-button>
        </div>
        <div class="col-md-6 col-xl-auto col-md-auto p-1" v-if="releasePermission">
          <el-button
            icon="el-icon-s-claim"
            class="w-70"
            type="success"
            size="small"
            style="min-width:120px;font-size:14px"
            @click="handleRelease"
          >
            Release
          </el-button>
        </div>
      </div>
    </div>
  </div>

  <div class="row" v-if="isLoaded">
    <div class="col-5 mt-2 mt-md-0 col-md-auto d-flex align-items-center" style="padding: 10px">
      <el-input :placeholder="item.version" disabled clearable size="large" />
    </div>
    <div class="col-5 mt-2 mt-md-0 col-md-auto d-flex align-items-center" style="padding: 10px">
      <el-button-group>
        <el-button size="mini" :class="{ '--clicked': editorNum === 0 }" @click="changeLanguage(0)">
          <img class="icon-flag" :src="require('../../assets/images/flag/united-states.png')" />
        </el-button>
        <el-button size="mini" :class="{ '--clicked': editorNum === 1 }" @click="changeLanguage(1)">
          <img class="icon-flag" :src="require('../../assets/images/flag/spain.png')" />
        </el-button>
        <el-button size="mini" :class="{ '--clicked': editorNum === 2 }" @click="changeLanguage(2)">
          <img class="icon-flag" :src="require('../../assets/images/flag/brazil.png')" />
        </el-button>
      </el-button-group>
      <el-button-group v-if="editMode" class="ms-3">
        <el-button :class="{ '--clicked': viewMode }" size="small" @click="handleView" circle>
          <i class="bi bi-camera-fill" style="font-size:14px"></i>
        </el-button>
      </el-button-group>
    </div>
  </div>

  <div class="col-12" v-if="editMode === false && isLoaded">
    <div class="card border p-5">
      <div class="html_preview" v-html="item.messages[editorNum].content" />
    </div>
  </div>

  <!-- editor -->
  <div class="row" v-if="editMode === true">
    <div :class="viewMode ? 'col-md-7' : 'col-md-12'">
      <SnapshotsEditor
        v-if="editorNum === 0"
        :index="editorNum"
        :default-content="item.messages[editorNum].content"
        @passData="messageContentUpdate"
      />
      <SnapshotsEditor
        v-if="editorNum === 1"
        :index="editorNum"
        :default-content="item.messages[editorNum].content"
        @passData="messageContentUpdate"
      />
      <SnapshotsEditor
        v-if="editorNum === 2"
        :index="editorNum"
        :default-content="item.messages[editorNum].content"
        @passData="messageContentUpdate"
      />
    </div>
    <div class="col-md-5" v-if="viewMode">
      <div class="card border p-5">
        <div class="html_preview" v-html="item.messages[editorNum].content" />
      </div>
    </div>
  </div>
</template>

<script>
  import SnapshotsEditor from '@/views/Snapshots/SnapshotsEditor'
  export default {
    name: 'SnapshotsDetails',
    components: { SnapshotsEditor },
    data() {
      return {
        viewMode: false,
        loading: true,
        item: null,
        editorNum: 0,
        showBtn: true,
        editMode: false,
        status: 'DRAFT',
        managePermission: false,
        releasePermission: false,
      }
    },
    computed: {
      isLoaded() {
        return !!this.item
      },
    },
    methods: {
      async validatePermissionForManage() {
        try {
          this.managePermission = await this.$services.users.validate('MANAGE_SNAPSHOTS')
        } catch (error) {
          console.log(error)
        }
      },
      async validatePermissionForRelease() {
        try {
          this.releasePermission = await this.$services.users.validate('RELEASE_SNAPSHOTS')
        } catch (error) {
          console.log(error)
        }
      },
      async loadSnapshot() {
        try {
          this.loading = true

          const { id } = this.$route.params
          this.item = await this.$services.snapshots.show(id)

          if (!this.item) {
            this.$message({
              type: 'error',
              message: 'Snapshot not found.',
            })
            await this.$router.push('/snapshots')
          }
        } finally {
          this.loading = false
        }
      },
      handleEdit() {
        this.changeMode()
      },
      handleView() {
        this.viewMode = !this.viewMode
      },
      async handleSave() {
        try {
          const { id } = this.$route.params

          await this.$services.snapshots.update(id, this.createData())
          this.$message({
            type: 'success',
            message: 'Snapshot updated successfully!',
          })
        } catch (err) {
          this.$message({
            type: 'error',
            message: 'Fail to update snapshot, please try it later',
          })
          await this.$router.push('/snapshots')
        } finally {
          this.changeMode()
        }
      },
      handleDelete() {
        this.$confirm('This will permanently delete the snapshot. Confirm?', 'Warning', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
          .then(() => {
            this.deleteSnapshot()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'Delete canceled',
            })
          })
      },
      async deleteSnapshot() {
        try {
          const { id } = this.$route.params
          await this.$services.snapshots.remove(id)
          this.$message({
            type: 'success',
            message: 'Snapshot has been deleted successfully.',
          })
          await this.$router.push('/snapshots')
        } catch (error) {
          this.$message({
            type: 'error',
            message: 'Fail to delete snapshot, please try it later',
          })
        }
      },
      handleRelease() {
        this.$confirm('This will release the snapshot to Client. Confirm?', 'Warning', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
          .then(() => {
            this.releaseSnapshot()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'Release canceled',
            })
          })
      },
      async releaseSnapshot() {
        if (this.editMode === false) {
          try {
            const { id } = this.$route.params
            await this.$services.snapshots.release(id)
            this.$message({
              type: 'success',
              message: 'Snapshot released successfully!',
            })
            await this.$router.push('/snapshots')
          } catch (error) {
            this.$message({
              type: 'error',
              message: 'Fail to release snapshot, please try it later',
            })
          }
        } else {
          this.$message({
            type: 'warning',
            message: 'Please save the snapshot before you release it',
          })
        }
      },
      messageContentUpdate(index, value) {
        this.item.messages[index].content = value
      },
      createData() {
        return {
          'version': this.item.version,
          'messages': this.item.messages,
        }
      },
      changeLanguage(index) {
        this.editorNum = index
      },
      changeMode() {
        this.editMode = !this.editMode
      },
    },
    mounted() {
      this.loadSnapshot()
      this.validatePermissionForManage()
      this.validatePermissionForRelease()
    },
  }
</script>

<style lang="scss" scoped>
  .container-header {
    display: flex;
  }
  .icon-flag {
    height: auto;
    width: auto;
    max-height: 20px;
    max-width: 20px;
  }
  .el-button-group {
    .el-button {
      background: white;
      border: none;
      &.--clicked {
        background: #b3e5fc;
      }
    }
    .el-button:focus {
      outline: none;
    }
  }
  .html_preview {
    ul {
      padding-left: 30px !important;
    }
    height: 60vh;
    overflow-y: scroll;
  }
</style>
