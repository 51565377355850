<template>
  <div class="card border p-2">
    <div class="editor-menu py-0" v-if="editor">
      <div class="editor-menu_group">
        <el-button-group>
          <el-button
            type="primary"
            plain
            size="mini"
            @click="
              editor
                .chain()
                .focus()
                .toggleBold()
                .run()
            "
            :class="{ 'is-active': editor.isActive('bold') }"
          >
            <i class="fas fa-bold fa-lg"></i>
          </el-button>

          <el-button
            type="primary"
            plain
            size="mini"
            @click="
              editor
                .chain()
                .focus()
                .toggleItalic()
                .run()
            "
            :class="{ 'is-active': editor.isActive('italic') }"
          >
            <i class="fas fa-italic fa-lg"></i>
          </el-button>

          <el-button
            type="primary"
            plain
            size="mini"
            @click="
              editor
                .chain()
                .focus()
                .toggleStrike()
                .run()
            "
            :class="{ 'is-active': editor.isActive('strike') }"
          >
            <i class="fas fa-strikethrough fa-lg"></i>
          </el-button>

          <el-button
            type="primary"
            plain
            size="mini"
            @click="
              editor
                .chain()
                .focus()
                .toggleCode()
                .run()
            "
            :class="{ 'is-active': editor.isActive('code') }"
          >
            <i class="fas fa-code fa-lg"></i>
          </el-button>

          <el-button
            type="primary"
            plain
            size="mini"
            @click="
              editor
                .chain()
                .focus()
                .toggleCode()
                .run()
            "
            :class="{ 'is-active': editor.isActive('underline') }"
          >
            <i class="fas fa-underline fa-lg"></i>
          </el-button>
        </el-button-group>
      </div>

      <div class="editor-menu_group">
        <el-button-group>
          <el-button
            type="primary"
            plain
            size="mini"
            @click="
              editor
                .chain()
                .focus()
                .toggleHeading({ level: 1 })
                .run()
            "
            :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
          >
            <strong>H1</strong>
          </el-button>
          <el-button
            type="primary"
            plain
            size="mini"
            @click="
              editor
                .chain()
                .focus()
                .toggleHeading({ level: 2 })
                .run()
            "
            :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
          >
            <strong>H2</strong>
          </el-button>
          <el-button
            type="primary"
            plain
            size="mini"
            @click="
              editor
                .chain()
                .focus()
                .toggleHeading({ level: 3 })
                .run()
            "
            :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
          >
            <strong>H3</strong>
          </el-button>
          <el-button
            type="primary"
            plain
            size="mini"
            @click="
              editor
                .chain()
                .focus()
                .toggleBulletList()
                .run()
            "
            :class="{ 'is-active': editor.isActive('bulletList') }"
          >
            <i class="fas fa-list-ul fa-lg"></i>
          </el-button>
          <el-button
            type="primary"
            plain
            size="mini"
            @click="
              editor
                .chain()
                .focus()
                .toggleOrderedList()
                .run()
            "
            :class="{ 'is-active': editor.isActive('orderedList') }"
          >
            <i class="fas fa-list-ol fa-lg"></i>
          </el-button>
        </el-button-group>
      </div>

      <div class="editor-menu_group">
        <el-button-group>
          <el-button
            type="primary"
            plain
            size="mini"
            @click="
              editor
                .chain()
                .focus()
                .toggleBlockquote()
                .run()
            "
            :class="{ 'is-active': editor.isActive('blockquote') }"
          >
            <i class="fas fa-quote-left fa-lg"></i>
          </el-button>
          <el-button
            type="primary"
            plain
            size="mini"
            @click="
              editor
                .chain()
                .focus()
                .setHorizontalRule()
                .run()
            "
          >
            <i class="fas fa-ruler-horizontal fa-lg"></i>
          </el-button>
        </el-button-group>
      </div>

      <div class="editor-menu_group">
        <el-button-group>
          <el-button
            type="primary"
            plain
            size="mini"
            @click="
              editor
                .chain()
                .focus()
                .undo()
                .run()
            "
          >
            <i class="fas fa-undo fa-lg"></i>
          </el-button>
          <el-button
            type="primary"
            plain
            size="mini"
            @click="
              editor
                .chain()
                .focus()
                .redo()
                .run()
            "
          >
            <i class="fas fa-redo fa-lg"></i>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <el-divider class="mt-0" />
    <div class="editor-content">
      <editor-content :editor="editor" />
    </div>
  </div>
</template>

<script>
  import { Editor, EditorContent } from '@tiptap/vue-3'
  import { defaultExtensions } from '@tiptap/starter-kit'
  export default {
    name: 'SnapshotsEditor',
    components: { EditorContent },
    props: {
      index: { type: Number, required: true, default: 0 },
      defaultContent: { type: String, default: '' },
    },
    data() {
      return {
        editor: null,
      }
    },
    watch: {},
    methods: {},

    mounted() {
      const exportHtml = () => {
        this.$emit('passData', this.index, this.editor.html)
      }

      this.editor = new Editor({
        extensions: [...defaultExtensions()],
        injectCSS: true,
        content: '',
        html: '',
        onUpdate() {
          this.html = this.getHTML()
          exportHtml()
        },
      })
      this.editor.commands.setContent(this.$props.defaultContent)
    },

    beforeUnmount() {
      this.editor.destroy()
    },
  }
</script>

<style lang="scss">
  .editor-menu {
    display: flex;

    padding-top: 10px;
    padding-bottom: 10px;

    .editor-menu_group {
      margin: 0 0 5px 5px;
    }
  }
  .editor-content {
    ul,
    ol {
      padding-left: 16px !important;
      margin-top: 12px !important;
      margin-bottom: 16px !important;
    }
    hr {
      border-top: 2px solid !important;
      margin-top: 32px !important;
      margin-bottom: 32px !important;
    }
  }
  .ProseMirror:focus {
    outline: none;
  }
  .ProseMirror {
    height: 60vh;
    overflow-y: scroll;
    border-radius: 15px;
    font-size: 16px;
    padding-left: 5px;
    font-family: Arial, Helvetica, sans-serif;
    > * + * {
      margin-top: 0.75em;
    }

    b,
    strong {
      font-weight: bolder;
    }

    ul,
    ol {
      padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }

    code {
      background-color: rgba(#616161, 0.1) !important;
      color: #616161 !important;
    }

    pre {
      background: #0d0d0d;
      color: #fff;
      font-family: 'JetBrainsMono', monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0d0d0d, 0.4);
    }

    hr {
      border: none;
      border-top: 2px solid rgba(#0d0d0d, 0.1);
      margin: 2rem 0;
    }
  }
</style>
