<template>
  <div class="row mb-0 mb-xl-1">
    <div class="col-auto d-none d-sm-block">
      <h3 class="h3 mb-3">New Snapshot</h3>
    </div>
    <div class="row">
      <div class="col-5 mt-2 mt-md-0 col-md-auto d-flex align-items-center" style="padding: 10px">
        <el-input placeholder="Version index" v-model="version" clearable size="large" />
      </div>
      <div class="col-5 mt-2 mt-md-0 col-md-auto d-flex align-items-center" style="padding: 10px">
        <el-button-group>
          <el-button
            size="mini"
            :class="{ '--clicked': editorNum === 0 }"
            @click="changeLanguage(0)"
          >
            <img class="icon-flag" :src="require('../../assets/images/flag/united-states.png')" />
          </el-button>
          <el-button
            size="mini"
            :class="{ '--clicked': editorNum === 1 }"
            @click="changeLanguage(1)"
          >
            <img class="icon-flag" :src="require('../../assets/images/flag/spain.png')" />
          </el-button>
          <el-button
            size="mini"
            :class="{ '--clicked': editorNum === 2 }"
            @click="changeLanguage(2)"
          >
            <img class="icon-flag" :src="require('../../assets/images/flag/brazil.png')" />
          </el-button>
        </el-button-group>
        <el-button-group class="ms-3">
          <el-button :class="{ '--clicked': viewMode }" size="small" @click="handleView" circle>
            <i class="bi bi-camera-fill" style="font-size:14px"></i>
          </el-button>
        </el-button-group>
      </div>
    </div>

    <div class="row">
      <div :class="viewMode ? 'col-md-7' : 'col-md-12'">
        <SnapshotsEditor
          v-if="editorNum === 0"
          :index="editorNum"
          :default-content="messages[0].content"
          @passData="messageContentUpdate"
        />
        <SnapshotsEditor
          v-if="editorNum === 1"
          :index="editorNum"
          :default-content="messages[1].content"
          @passData="messageContentUpdate"
        />
        <SnapshotsEditor
          v-if="editorNum === 2"
          :index="editorNum"
          :default-content="messages[2].content"
          @passData="messageContentUpdate"
        />
      </div>
      <div class="col-md-5" v-if="viewMode">
        <div class="card border p-5">
          <div class="html_preview" v-html="messages[editorNum].content" />
        </div>
      </div>
    </div>
    <footer class="snapshots-footer">
      <el-button type="primary" v-on:click="handleSubmit">Submit</el-button>
      <router-link to="/snapshots">
        <el-button type="default">Cancel</el-button>
      </router-link>
    </footer>
  </div>
</template>
<script>
  import SnapshotsEditor from '@/views/Snapshots/SnapshotsEditor'
  export default {
    name: 'SnapshotsCreate',
    components: { SnapshotsEditor },
    data() {
      return {
        showBtn: false,
        viewMode: false,
        submitting: false,
        editorNum: 0,
        version: '',
        messages: [
          {
            'language': 'en-US',
            'content': '',
          },
          {
            'language': 'es-ES',
            'content': '',
          },
          {
            'language': 'pt-BR',
            'content': '',
          },
        ],
      }
    },
    methods: {
      handleSubmit() {
        this.$confirm('This will submit the snapshot with draft status. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
          .then(() => {
            this.submitSnapshot()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'Canceled',
            })
          })
      },
      async submitSnapshot() {
        try {
          this.submitting = true
          this.error = {}
          //if function return null, should receive err from backend and then handle it
          await this.$services.snapshots.create(this.createDt())
          this.$message({
            type: 'success',
            message: `The snapshot was successfully created!`,
          })
          await this.$router.push(`/snapshots`)
        } catch (err) {
          this.$message({
            type: 'error',
            message: err.response.data.data[0].error,
          })
        } finally {
          this.submitting = false
        }
      },
      //create data json file
      createDt() {
        return {
          'version': this.version,
          'messages': [
            {
              'language': this.messages[0].language,
              'content': this.messages[0].content,
            },
            {
              'language': this.messages[1].language,
              'content': this.messages[1].content,
            },
            {
              'language': this.messages[2].language,
              'content': this.messages[2].content,
            },
          ],
        }
      },
      //function to update message[index].content,
      messageContentUpdate(index, value) {
        this.messages[index].content = value
      },
      //switch language editor,
      changeLanguage(index) {
        this.editorNum = index
      },
      handleView() {
        this.viewMode = !this.viewMode
      },
      async validatePermission() {
        try {
          this.showBtn = await this.$services.users.validate('MANAGE_SNAPSHOTS')
        } catch (error) {
          console.log(error)
        }
      },
    },
    mounted() {
      this.validatePermission()
    },
  }
</script>

<style lang="scss" scoped>
  .icon-flag {
    height: auto;
    width: auto;
    max-height: 20px;
    max-width: 20px;
  }
  .el-button-group {
    .el-button {
      background: white;
      border: none;
      &.--clicked {
        background: #b3e5fc;
      }
    }
    .el-button:focus {
      outline: none;
    }
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .snapshots-footer {
    align-items: flex-start;
    .el-button {
      margin: 7px 10px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .html_preview {
    ul {
      padding-left: 30px !important;
    }
    height: 60vh;
    overflow-y: scroll;
  }
</style>
