<template>
  <main class="row" v-loading="loading">
    <div class="card">
      <div class="card-header pb-0">
        <div class="col-auto ms-auto text-end mt-n1">
          <el-select class="border-0" v-model="pageSize" placeholder="Select" size="mini">
            <el-option
              v-for="item in pageOptions"
              :key="item"
              :label="item.type"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <table class="table table-striped table-hover mb-5 mt-2" style="width: 100%">
        <thead>
          <tr>
            <th>
              <a v-on:click="handleSortIconClick('first')" v-if="firstSortIcon % 3 === 0">
                Version <i class="fas fa-sort" />
              </a>
              <a v-on:click="handleSortIconClick('first')" v-if="firstSortIcon % 3 === 1">
                Version <i class="fas fa-sort-up" />
              </a>
              <a v-on:click="handleSortIconClick('first')" v-if="firstSortIcon % 3 === 2">
                Version <i class="fas fa-sort-down" />
              </a>
            </th>
            <th class="d-none d-xxl-table-cell">
              <a v-on:click="handleSortIconClick('second')" v-if="secondSortIcon % 3 === 0">
                Last Update <i class="fas fa-sort" />
              </a>
              <a v-on:click="handleSortIconClick('second')" v-if="secondSortIcon % 3 === 1">
                Last Update <i class="fas fa-sort-up" />
              </a>
              <a v-on:click="handleSortIconClick('second')" v-if="secondSortIcon % 3 === 2">
                Last Update <i class="fas fa-sort-down" />
              </a>
            </th>
            <th class="d-none d-xxl-table-cell">
              <a v-on:click="handleSortIconClick('third')" v-if="thirdSortIcon % 3 === 0">
                Status <i class="fas fa-sort" />
              </a>
              <a v-on:click="handleSortIconClick('third')" v-if="thirdSortIcon % 3 === 1">
                Status <i class="fas fa-sort-up" />
              </a>
              <a v-on:click="handleSortIconClick('third')" v-if="thirdSortIcon % 3 === 2">
                Status <i class="fas fa-sort-down" />
              </a>
            </th>
            <th class="d-xl-table-cell text-end">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in paginatedTableData" :key="item.id">
            <td>
              <div style="font-size:0.95rem">
                <strong>{{ item.version }}</strong>
              </div>
            </td>
            <td class="d-none d-xxl-table-cell">
              <div style="font-size:0.95rem">{{ lastUpdateTime(item.updated_at) }}</div>
            </td>
            <td class="d-none d-xxl-table-cell">
              <el-tag :type="statusToTag(item.status)">{{ item.status.toUpperCase() }}</el-tag>
            </td>
            <td class="d-xl-table-cell text-end">
              <router-link :to="`/snapshots/details/${item.id}`">
                <button class="btn btn-primary btn-vimeo">
                  <i class="fas fa-info fa-lg" />
                </button>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- pagination -->
      <nav>
        <div class="row">
          <div class="col-sm-12 col-md-5">
            <span
              >Show {{ lowerBound }} to {{ upperBound }} of {{ snapshots.length }} Snapshots</span
            >
          </div>
          <div class="col-sm-12 col-md-7">
            <ul class="pagination justify-content-end me-3">
              <li class="page-item" :class="selectedPage === 1 ? 'disabled' : ''">
                <a class="page-link border-0" v-on:click="handlePagination(-1)">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>

              <li
                v-for="page in paginationIndexes"
                :key="page"
                class="page-item"
                :class="isSelected(page) ? 'active' : ''"
              >
                <a class="page-link border-0" v-on:click="handlePaginationIndex(page)">
                  {{ page }}
                </a>
              </li>

              <li
                class="page-item"
                :class="selectedPage === paginationIndexes.length ? 'disabled' : ''"
              >
                <a class="page-link border-0" v-on:click="handlePagination(1)">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </main>
</template>

<script>
  export default {
    name: 'SnapshotsTable',
    props: {
      snapshots: {
        type: Array,
        default: () => [],
      },
      loading: {
        type: Boolean,
        default: () => false,
      },
    },
    data() {
      return {
        firstSortIcon: 0,
        secondSortIcon: 0,
        thirdSortIcon: 0,
        selectedPage: 1,
        pageSize: 10,
        pageOptions: [
          { type: '10 instances', value: 10 },
          { type: '20 instances', value: 20 },
          { type: '50 instances', value: 50 },
        ],
      }
    },
    computed: {
      upperBound() {
        const res = this.selectedPage * this.pageSize
        return res > this.snapshots.length ? this.snapshots.length : res
      },
      lowerBound() {
        return (this.selectedPage - 1) * this.pageSize + 1
      },
      tableData() {
        /* check whether User ask to sort or not */
        /* pass the param you want to sort, and descending or ascending (-1 or 1) */
        if (this.firstSortIcon % 3 === 1) return this.sortedData('first', 1)
        else if (this.firstSortIcon % 3 === 2) return this.sortedData('first', -1)
        if (this.secondSortIcon % 3 === 1) return this.sortedData('second', 1)
        else if (this.secondSortIcon % 3 === 2) return this.sortedData('second', -1)
        if (this.thirdSortIcon % 3 === 1) return this.sortedData('third', 1)
        else if (this.thirdSortIcon % 3 === 2) return this.sortedData('third', -1)
        return this.snapshots
      },
      paginatedTableData() {
        const initialIdx = this.pageSize * (this.selectedPage - 1)
        return this.tableData.slice(initialIdx, initialIdx + this.pageSize)
      },
      paginationIndexes() {
        const pages = Math.ceil(this.tableData.length / this.pageSize)
        let page_arr = []
        for (let i = 1; i <= pages; i++) {
          page_arr.push(i)
        }
        return page_arr
      },
    },
    methods: {
      lastUpdateTime(dt) {
        if (dt.length > 0) {
          return dt
            ? this.$common.convertTime(dt.substr(11, 5)) + ', ' + String(dt).substr(0, 10)
            : 'Unknown'
        }
        return ''
      },
      handleSortIconClick(col) {
        switch (col) {
          case 'first':
            this.firstSortIcon++
            break
          case 'second':
            this.secondSortIcon++
            break
          default:
            this.thirdSortIcon++
        }
        this.resetSortIconValue(col)
      },
      resetSortIconValue(col) {
        if (col !== 'first') this.firstSortIcon = 0
        if (col !== 'second') this.secondSortIcon = 0
        if (col !== 'third') this.thirdSortIcon = 0
      },
      handlePagination(selectedPage) {
        this.selectedPage += selectedPage
      },
      handlePaginationIndex(selectedPage) {
        this.selectedPage = selectedPage
      },
      isSelected(pageIndex) {
        return pageIndex === this.selectedPage
      },
      statusToTag(type) {
        switch (type) {
          case 'RELEASED':
            return 'success'
          case 'DRAFT':
            return 'info'
          default:
            return 'info'
        }
      },
      sortedData(param, sign) {
        let res = this.snapshots
        return res.sort((a, b) => {
          /* default is name */
          let paramA = a.version.toUpperCase() // ignore upper and lowercase
          let paramB = b.version.toUpperCase() // ignore upper and lowercase
          if (param === 'second') {
            paramA = a.updated_at.toUpperCase() // ignore upper and lowercase
            paramB = b.updated_at.toUpperCase() // ignore upper and lowercase
          }
          if (param === 'third') {
            paramA = a.status.toUpperCase() // ignore upper and lowercase
            paramB = b.status.toUpperCase() // ignore upper and lowercase
          }
          if (paramA < paramB) {
            return -1 * sign
          }
          if (paramA > paramB) {
            return 1 * sign
          }
          // if instance param value are same
          return 0
        })
      },
    },
    watch: {
      snapshots() {
        this.selectedPage = 1
      },
      pageSize() {
        this.selectedPage = 1
      },
    },
  }
</script>

<style scoped></style>
